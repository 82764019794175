<template lang="pug">
Navbar
template(v-if="!isMobileApp")
  .padding-top
    HeadingLayout(:title="title", :desc="desc")
template(v-else)
  HeadingLayout.mt-3(:title="title", :desc="desc")
.container
  .row.pb-5
    .col-12
      template(v-for="term in termService")
        .pb-3
          .fs-4.fw-bold {{ term.title }}
          template(v-for="subTerm in term.description")
            p {{ subTerm }}
Footer
</template>

<script>
import HeadingLayout from "../layout/HeadingLayout";
import Navbar from "../components/TheNavbar";
import Footer from "../components/TheFooter";
export default {
  name: "Policy",
  components: { Footer, Navbar, HeadingLayout },
  data() {
    return {
      isMobileApp: true,
      title: "Kloser Privacy Policy",
      desc: "Last revised: 3 January, 2022",
      termService: [
        {
          title: "",
          description: [
            "We view and treat your Personal Data seriously.",
            "In order for us to provide you with the best possible service and offers on the Kloser Mobile Application (“App”), the Kloser Website and any other platforms related when utilising the services for Kloser (“the Kloser Platforms”) we, Kloser Sdn. Bhd. referred to as “Kloser”, “we”, “us” or “our”), will be processing your Personal Data when you register on our website and/or our mobile app. Therefore, we have put together a Privacy Policy that informs you how we will collect, record, hold, disclose, transfer, store and otherwise process your Personal Data. All of these activities are essential for us to perform the branding, lead generation and Customer Relationship Management (“CRM”) services to you on the Kloser Platforms. Your consent is given to Kloser Sdn. Bhd.",
            "By providing your Personal Data to us (whether through our website/App, or any other channels), you hereby consent that we may collect, record, hold, disclose, transfer, store and otherwise process all or any part of your Personal Data in accordance with this Privacy Policy. This consent shall remain valid so long as we do not receive any written notice of withdrawal of consent from you.",
            "Please take some time to read our Privacy Policy so that you understand your rights on the matter.",
          ],
        },
        {
          title: "1. Collection and Use of Personal Data",
          description: [
            "Your Personal Data is information that relates directly or indirectly to you, who is identified or identifiable from that information or from that and other information. This includes any sensitive personal data, political opinion, religious belief or any other beliefs. During the course of your transactions and dealings with us and/or our trusted business partners and agents, you may be requested to furnish your Personal Data from time to time. We gather data and work with our trusted business partners and agents in compliance with this Privacy Policy to provide, support and improve our products and services to you.",
          ],
        },
        {
          title: "2. Personal Data that we may collect",
          description: [
            "When you register to become a user of our website/App or send information to us via our official WhatsApp communications channel or any other channels, we may collect a variety of your Personal Data, which may include (but not limited to) your:",
            "\u00A0\u00A0 \u2022 Name",
            "\u00A0\u00A0 \u2022 National registration identity card number",
            "\u00A0\u00A0 \u2022 Email address",
            "\u00A0\u00A0 \u2022 Mobile phone number",
            "\u00A0\u00A0 \u2022 Mobile phone contacts",
            "\u00A0\u00A0 \u2022 Mobile line/Carrier provider",
            "\u00A0\u00A0 \u2022 Location Data",
            "The list of Personal Data stated above is not exhaustive and may include other personal data depending on the nature of dealings or transactions. The request for these other personal data may be indicated on the relevant form that you need to fill in, depending on the nature of the dealings or transactions, and for which your consent is granted, which extends to such personal data, unless you inform us otherwise in writing.",
          ],
        },
        {
          title: "3. How we use your Personal Data",
          description: [
            "We may process your Personal Data for our existing and future business purposes, including to provide better services to you by delivering and supporting any products and services which we believe you may find useful or appropriate to you and which we may communicate, directly or indirectly, to you (save for any specific products or services which you have opted out of in writing). Listed below are non-exhaustive details of how we use your personal data. Personal Data under this Privacy Policy shall refer to personal data that is collected prior to, on or after the date of your consent to this Privacy Policy or the date of this Privacy Policy.",
            "3.1 We collect, use, disclose, and otherwise process your Personal Data for (but not limited to) the following purposes:",
            "\u00A0\u00A0 \u2022 in all matters pertaining to the contract entered or to be entered into between you and us, or between you and any or our business partners",
            "\u00A0\u00A0 \u2022 managing the infrastructure and our business operations and complying with our internal policies and procedures",
            "\u00A0\u00A0 \u2022 verifying your identity",
            "\u00A0\u00A0 \u2022 protecting and enforcing our contractual and legal rights and obligations",
            "\u00A0\u00A0 \u2022 conducting audits, reviews and analysis of our internal processes, action planning and managing commercial risks",
            "\u00A0\u00A0 \u2022 complying with any applicable rules, laws and regulations, codes of practice or guidelines or to assist in law enforcement and investigations by relevant authorities",
            "\u00A0\u00A0 \u2022 directly or indirectly circulating, transmitting and/or delivering to you, by any means (including emails, phone calls, video calls, short messaging services, regular mails, push notifications on mobile devices and other means), any promotional, informational, advertising materials (including products, services, new launches, upcoming events, promotions, advertisements, marketing and commercial materials) in respect of our or third party products and services. In doing so, we may work with our trusted business partners and agents. This means that we may give them access to your Personal Data",
            "\u00A0\u00A0 \u2022 creating custom audience groups from your mobile contacts to be utilised in your or our marketing activities",
            "\u00A0\u00A0 \u2022 for the purposes of record-keeping in the ordinary course of our business",
            "\u00A0\u00A0 \u2022 any other purpose relating to any of the above; and/or",
            "\u00A0\u00A0 \u2022 for the implementation and execution of any of the above, including our or third party products and services you may have opted for.",
            "3.2 In addition, we may collect, use, disclose and otherwise process your Personal Data for the following purposes, depending on the nature of our relationship with you:",
            "\u00A0\u00A0 \u2022 if you have a subscription or membership account with us",
            "\u00A0\u00A0 \u2022 to optimize and customize your user experience with our website and mobile app, and any other Kloser platforms in the future",
            "\u00A0\u00A0 \u2022 to process your application for online subscription services",
            "\u00A0\u00A0 \u2022 to maintain your account with us",
            "\u00A0\u00A0 \u2022 to conduct regular checks at any time and from time to time as deemed fit and necessary by us with the intention to obtain regular customer data updates",
            "\u00A0\u00A0 \u2022 to verify and process your personal details and payments in relation to provision of online subscription service to you",
            "\u00A0\u00A0 \u2022 to provide you with the services which you have signed up for and other services which we may, directly or indirectly offer to you",
            "\u00A0\u00A0 \u2022 to provide you the products which you have paid for and other products which we may, directly or indirectly, offer to you",
            "\u00A0\u00A0 \u2022 to keep you posted on our latest services or product announcements and upcoming events",
            "\u00A0\u00A0 \u2022 resolving complaints and handling requests and enquiries",
            "\u00A0\u00A0 \u2022 to conduct market research for statistical, profiling and statistical analysis for the improvement of services provided to you",
            "\u00A0\u00A0 \u2022 to send important notices, push notifications on mobile devices, emails, letters, short messaging service (SMS) and WhatsApp messages, like communications about purchases and changes to our terms, conditions, and policies",
            "\u00A0\u00A0 \u2022 to share your Personal Data with our trusted business partners and agents for the purposes of (but not limited to) fulfillment of customer orders, processing and delivery of financial products to you; and/or",
            "for any of the purposes relating to the above.",
            "You hereby agree and understand that collection and processing of your Personal Data by us is not limited to facilitating the purchase of any subscription or services within the Kloser platforms. The purpose of such collection and retention and otherwise processing of Personal Data is towards our partnering/assisting you in services offered by us, our trusted business partners, agents and third parties. In order to assist you with other services in the future, we will retain your data even after the completion of the transaction. By filling in your Personal Data on the Kloser platforms or otherwise providing your Personal Data to us, you expressly consent to our retention of the same. If you do not wish to have your Personal Data being processed by us, you may opt out of our services to stop any future collection of Personal Data.",
          ],
        },
        {
          title: "4. Impact Resulting from Failure to Supply Data",
          description: [
            "The failure to supply Personal Data may affect our ability to deliver optimised user experience services to our registered users. In addition, failure to provide certain essential Personal Data may result in us being unable to provide you with the services and/or products that you have opted for.",
          ],
        },
        {
          title: "5. Disclosure of Personal Data",
          description: [
            "Personal Data provided to us will generally be kept confidential but you hereby consent and authorize us to provide or disclose your Personal Data to the following categories of parties:",
            "A: any person or authority to whom we are compelled or required to do so under law, court order or in response to a competent or government agency",
            "B: our business partners and affiliates that provide the services that you have opted for or related services or products in connection with our platforms including, but not limited to market research firms, third party marketing entities, electronic-know your customer (e-KYC) operators, and entities engaged involved in performing our services to you on our platforms, and any other industries incidental to the use of your Personal Data as stated in Clause 3 above",
            "C: members of our Group companies and their affiliates",
            "D: our auditors, consultants, accountants, lawyers or other financial or professional advisers",
            "E: such sub-contractors or third party service or trusted business partners as we believe to be necessary or appropriate.",
          ],
        },
        {
          title: "6. Protection of Personal Data",
          description: [
            "We take the necessary precautions, both administrative and technical to safeguard your Personal Data against loss, theft, misuse and unauthorized access, disclosure, usage, alteration or destruction.",
            "We ensure that all information collected will be safely and securely stored.",
          ],
        },
        {
          title: "7. Retention of Personal Data and Access",
          description: [
            "We shall endeavour to make it easy for you to keep your Personal Data accurate, complete and up to date. We will retain your Personal Data for the period necessary to fulfill the many different purposes outlined in this Privacy Policy unless a longer retention period is permitted or required by law. You can help ensure that your contact information and preferences are accurate, complete, and up to date by updating your information via the Kloser Platforms. For other personal information, we make good faith efforts to provide you with access so you can request that we correct the data if it is inaccurate or delete the data if we are not required to retain it by law or for legitimate business purposes. We may decline to process requests that are unreasonably repetitive, require disproportionate technical effort, jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise required by local law.",
          ],
        },
        {
          title: "8. Collection of Computer Data not necessarily Personal Data",
          description: [
            "When you visit our platform(s), our servers will automatically record information that your browser sends whenever you visit a website or mobile application. This information may include (but not limited to):",
            "\u00A0\u00A0 \u2022 Your device’s IP address",
            "\u00A0\u00A0 \u2022 Browser type",
            "\u00A0\u00A0 \u2022 Cookies",
            "\u00A0\u00A0 \u2022 GTM Event Tag",
            "\u00A0\u00A0 \u2022 Webpage you were visiting before you came to our website",
            "\u00A0\u00A0 \u2022 The pages within the Kloser Platforms that you visit",
            "\u00A0\u00A0 \u2022 The time spent on those pages, products and information searched for on our site, access times and dates, and other statistics",
            "This information is collected for analysis and evaluation in order to help us improve our platforms and products we provide. The said information may be used in association with any other Personal Data.",
          ],
        },
        {
          title: "9. Cookies",
          description: [
            "In order for us to improve on the quality of our services and products as well as to customise user experiences, we may track user behaviour on our platforms by using “Cookies”. A cookie is a small text file that is stored on your computer / mobile phone that provides us with information about your online use of our platforms to help us customise your user experience and make our platforms more user friendly.",
            "Platform visitors who have yet to register as our users or have yet to log in as a user will be tracked anonymously. The data collected from such tracking will be generic and aggregated in nature and no individual data will be collected therefrom. In respect of registered users, we will use cookies to gather data on our user’s preferences provided that such cookies are secure in nature and based on HTTP only.",
            "If you have set your web browser to warn you before accepting cookies, you should receive a notice message with each cookie. You may decline the use of cookies by opting to turn them off in your web browser but please note that in doing so, this may affect our ability to render the best user experience on our platforms.",
          ],
        },
        {
          title: "10. Data Transfer & Sharing",
          description: [
            "We do not share with or transfer to third parties your Personal Data, except for circumstances where we have a good-faith belief that access, use, transfer or disclosure of such information is reasonably necessary or appropriate for the purposes of data storage or processing or providing any service or product on our behalf to you (including for the purposes set out in Clauses 3 and 5 above). We ensure that such information is provided to members of our Group companies and their affiliates and our trusted business partners and agents within or outside the country in which we or they are established, under conditions of confidentiality and similar levels of security safeguards.",
          ],
        },
        {
          title: "11. Other Matters",
          description: [
            "We reserve the right to update and amend this Privacy Policy from time to time. Any material changes will be posted on our website with the updated Privacy Policy.",
            "Your continued use or access of our platforms and/or services shall be treated as your consent for us to process your Personal Data in accordance with the updated Privacy Policy, unless you inform us otherwise in writing in which event paragraph 4 of this Privacy Policy will apply.",
            "Where you do not continue to use or access our platforms and/or services after the updated Privacy Policy comes into effect and you do not consent to us processing your Personal Data in accordance with the updated Privacy Policy, we will continue to process your Personal Data in accordance with the last version of the Privacy Policy which you have consented to, unless you inform us otherwise in writing in which event paragraph 4 of this Privacy Policy will apply.",
          ],
        },
      ],
    };
  },
  mounted() {
    this.isMobileApp = !!(
      this.$route.query && this.$route.query["is-mobile-app"]
    );
  },
};
</script>
